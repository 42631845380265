@import 'abstracts/variables';

@card-dimension: 250px;
@grid-gap: @spacer * 2;

.product-grid {
  --product-grid-card-width: unit(@card-dimension);
  --product-grid-gap: unit(@grid-gap);
  // Needs to be added as css variable to be accessible in javascript
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(@card-dimension, 1fr));
  grid-gap: @grid-gap;
  grid-auto-flow: row dense;
  margin-top: @spacer * 2;

  &.opacity {
    opacity: 0.5;
  }

  @media @below-sm {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: @spacer * 2 @spacer * 1.5;
  }

  @media @below-xs {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
}

.loader {
  position: relative;
  z-index: @z-index-color-palette; // To be below rsuite dropdowns
  margin-bottom: -98px - 2 * @spacer; // Height of loader + margin of element below
}

.skeleton-loading-card {
  max-width: 100% !important; // Override the inline styles applied on the Placeholder.Graph rsuite component
  height: 360px !important; // Magic number to fix the card height to match the card design
  border-radius: @border-radius;
}
