@import 'abstracts/variables';

.hero-section-container {
  @heading-font-size: 50px;
  @paragraph-font-size: 22px;
  @hero-section-stretch-margin: calc(((100vw - @max-width-lg) / 2) * -1);
  @content-padding: @spacer * 4;

  position: relative;
  margin-top: -(@spacer * 2); // Close gap between header and hero section
  margin-bottom: @spacer * 14; // Add space between hero section and category carousel
  background-color: @white;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(33, 33, 33, 0.1) 100%), url('https://aimeos-static-files.s3.eu-north-1.amazonaws.com/hero-section.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 @border-radius * 10 @border-radius * 10;

  // Stretch hero section to the edges of the screen on laptop/small desktop viewports
  @media @below-xxxl {
    margin-left: @hero-section-stretch-margin;
    margin-right: @hero-section-stretch-margin;
    border-radius: 0;
  }

  @media @below-xxl {
    margin-left: auto;
    margin-right: auto;
  }

  @media @below-md {
    margin-top: 0;
    margin-bottom: @spacer * 14;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 10%, rgba(33, 33, 33, 0.25) 100%), url('https://aimeos-static-files.s3.eu-north-1.amazonaws.com/hero-section.webp');
    background-position: right;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: @content-padding;
    gap: @spacer * 4;
    width: 100%;
    max-width: @max-width-lg;
    min-height: 500px;
    color: @white;

    @media @below-md {
      padding: @spacer * 2;
    }

    h1 {
      margin-top: -(@spacer * 5);
      font-size: @heading-font-size;
      font-weight: 900;
      color: @white;

      @media @below-md {
        margin-top: 0;
        font-size: @heading-font-size - 10;
      }

      @media @below-sm {
        font-size: @heading-font-size - 15;
      }
    }

    p {
      max-width: 580px;
      font-size: @paragraph-font-size;

      @media @below-sm {
        padding-bottom: @spacer * 10;
      }
    }
  }

  .features-banner-carousel {
    position: absolute;
    width: 100%;
    bottom: -120px;

    > div {
      margin: 0;
    }

    :global {
      .scroll-container-ref {
        transform: unset;
      }
    }

    .features-banner-carousel-card {
      &:first-child {
        margin-left: @content-padding;

        @media @below-xxxl {
          margin-left: calc((@hero-section-stretch-margin - @content-padding) * -1) !important; // Inverse back to positive with *-1
        }

        @media @below-xxl {
          margin-left: @content-padding !important;
        }

        @media @below-md {
          margin-left: @spacer * 2 !important;
        }
      }
    }
  }
}
